export const teamServiceTypeMapping = {
  "Chat": [
    "Chat"
  ],
  "Advertising and Marketing": [
    "Campaign Brief",
    "Copywriter",
    "Creative Ad Product Description",
    "Post Campaign Report",
    "Market Research",
    "Target Audience Analysis",
    "Social Media Content Generation",
    "Ad Copy Generation",
    "Brand Messaging",
    "Media Planning",
    "Digital Advertising Strategy",
    "Marketing Analytics and Reporting",
    "Email Composer and Responder",
    "Chat",
    //"Image Generator",
  ],
  "Commercial Editorial": [
    "AWIN Affiliate Offer Article Generator",
    "Skimlinks Affiliate Offer Article Generator",
    "Skimlinks Product Article Generator",
    "Amazon Affiliate Offer Article Generator",
    "Sponsored and Branded Content Article",
    "Commercial Content Editing",
  ],
  Editorial: [
    "Article Composer",
    "Article Composer (Google Search)",
    "Weather Article Composer (AccuWeather)",
    "Article Rewriter",
    "Article Adapter",
    "Copywriter",
    "Commenting Article Generator",
    "Public Notices Article Generator",
    "Public Notices Article Summary",
    "Football Match Report",
    "Interview or Speech to Article",
    "News Wire to Article",
    "Press Release to Article",
    "Recipe Creator",
    "Research Assistant",
    "Summarise This TL;DR",
    "Writers Assistant",
    "Product Review",
    "Service Review",
    "Restaurant Review",
    "Travel Review",
    "Editorial Calendar",
    "Content Planning",
    "Chat",
  ],
  // "Legal": [
  //   "Legal Research Assistant",
  //   "Legal Document Drafting",
  //   "GDPR Compliance Check",
  //   "Consent Management",
  //   "Legal Chatbot",
  //   "Review Contract"
  // ],
  Product: [
    "Jira Ticket Creator",
    "Product Roadmap",
    "Competitor Analysis",
    "User Feedback Analysis",
    "Feature Prioritisation",
    "User Persona Creation",
    "Product Requirements Documentation",
    "Market Research",
    "Product Launch Plan",
    "Product Pricing Strategy",
    "Jarvis Assistant",
  ],
  "Commercial Sales": [
    "Client Proposal",
    "Sales Presentation",
    "Sales Report",
    "Lead Generation",
    "Sales Pitch Deck",
    "Brand Messaging",
    "Media Planning",
    "Digital Advertising Strategy",
    "Email Composer and Responder",
    "Jarvis Assistant",
    "Chat",
  ],
  "HR": [
    "HR Comms",
    "Spreadsheets & Formula Generator",
    "L&D",
    "Job Description Generator",
    "Job Advert Generator",
    "HR Meeting/Interview Questions",
    "HR Policy Compliance",
    "HR FAQ Generator",
    "Telling a HR Data Story",
  ],
  // "Exec and Leadership": [
  //   "Executive Briefing",
  //   "Strategic Planning",
  //   "Financial Analysis",
  //   "Board Meeting Presentation",
  //   "Market Trends Analysis",
  //   "Competitor Intelligence",
  //   "Key Performance Indicators (KPI) Dashboard",
  //   "Investor Relations Brief",
  //   "Leadership Communication",
  //   "Mergers and Acquisitions Analysis"
  // ],
  // "Events Team": [
  //   "Event Planning Checklist",
  //   "Budget Management",
  //   "Venue Selection",
  //   "Vendor Coordination",
  //   "Event Promotion Strategy",
  //   "Event Logistics",
  //   "Guest List Management",
  //   "Agenda Creation",
  //   "Sponsorship Acquisition",
  //   "Post-Event Evaluation"
  // ],
  // "Customer Support": [
  //   "Customer Query Resolution",
  //   "Ticket Management",
  //   "Knowledge Base Creation",
  //   "FAQ Generation",
  //   "Live Chat Support",
  //   "Customer Feedback Analysis"
  // ],
  // "Finance and Accounting": [
  //   "Financial Reporting",
  //   "Budgeting and Forecasting",
  //   "Expense Management",
  //   "Tax Compliance",
  //   "Invoice Generation",
  //   "Financial Analysis"
  // ],
  // "IT and Technical Support": [
  //   "Troubleshooting Assistance",
  //   "System Monitoring and Maintenance",
  //   "Software Installation and Configuration",
  //   "Network Security Management",
  //   "IT Asset Inventory Management",
  //   "Technology Training and Documentation",
  //   "Jarvis Assistant",
  // ],
  // "Operations and Supply Chain": [
  //   "Inventory Management",
  //   "Order Fulfillment",
  //   "Supply Chain Planning",
  //   "Logistics Coordination",
  //   "Quality Control",
  //   "Process Improvement"
  // ],
  // "Research and Development": [
  //   "Research Assistant",
  //   "Patent Search",
  //   "Technology Scouting",
  //   "Prototyping and Testing",
  //   "Innovation Ideation",
  //   "Product Concept Development"
  // ],
  "Software Development": [
    "Jarvis Assistant",
    "Requirements Gathering",
    "Software Design and Architecture",
    "Coding Assistance",
    "Automated Testing",
    "Code Review",
    "Bug Tracking and Resolution",
    "Version Control",
    "Deployment Automation",
    "Continuous Integration and Deployment",
    "Technical Documentation",
  ],
};

export const batchServiceTypes = [
  {
    "label": "Social Headline",
    "value": "metaTitle"
  },
  {
    "label": "Newsletters And Referrers Headline",
    "value": "newsLettersAndReferrersHeadline"
  },
  {
    "label": "Lead Text",
    "value": "leadText"
  },
  {
    "label": "Meta Description",
    "value": "metaDescription"
  },
  {
    "label": "Article Title",
    "value": "title"
  },
  {
    "label": "Article Tags",
    "value": "tags"
  },
  {
    "label": "Lead Analysis",
    "value": "lead-analysis"
  },
  {
    "label": "Mirrorpix Title",
    "value": "mirrorpix-title"
  },
  {
    "label": "Mirrorpix Caption",
    "value": "mirrorpix-caption"
  },
  {
    "label": "Mirrorpix Tags",
    "value": "mirrorpix-tags"
  },
  {
    "label": "Mirrorpix Category",
    "value": "mirrorpix-category"
  },
  {
    "label": "Mirrorpix Infer Location",
    "value": "mirrorpix-infer-location"
  },
];

export const serviceTypes = Object.values(teamServiceTypeMapping)
  .flat()
  .reduce((uniqueOptions, serviceType) => {
    if (!uniqueOptions.some((option) => option.value === serviceType)) {
      uniqueOptions.push({ value: serviceType, label: serviceType });
    }
    return uniqueOptions;
  }, []);